import Card from '@material-ui/core/Card'
import { Alert, AlertTitle } from '@material-ui/lab';
import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';


function InfoAlert(props){
    const useStyles = makeStyles({
        root: {
          display: 'flex',
          justifyContent: 'center',
          alignSelf: 'center',
          marginTop:20,},
        alert:{
          background: '#eeeeee',
          width:'100%',
          paddingLeft:50,
          paddingRight:50,
        },
      });
    
      const classes = useStyles();
    
    return (
        <Card className={classes.root}>
        <Alert severity="info" className={classes.alert}>
          <AlertTitle>Info</AlertTitle>
              Attenzione — <strong>{props.text}</strong>
              <Link to="/">
                <h4>Torna indietro</h4>         
              </Link>
        </Alert>
        </Card>
      )
}

export default InfoAlert

