import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    rootSchedaElettorale: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop:20,
    },

    table:{
      width: '100%',
      alignItems:'center',
      
    },
    tableHead:{
      background:'#eeeeee',
      alignItems:'center',
    },

    PaperSchedaElettorale:{
        width: '80%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    titleSchedaElettorale: {
      flexGrow: 1,
      fontFamily:'"Segoe UI"',
      fontWeight:'500',
      fontSize: 17,
      lineHeight:'1',
    },
    
    textSchedaElettorale:{
      fontFamily:'"Segoe UI"',
      fontWeight:'Regular', 
      fontSize: 15,
    },
});

export default useStyles