import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles/titleBarTheme';
function TitleBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="sticky" className={classes.appbar} >
        <Toolbar> 
          <Typography className={classes.title}>
            CHIROTONIA
          </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default TitleBar