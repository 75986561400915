import React from 'react';
import SchedaElettorale from './SchedaElettorale';
import InfoVote from './getInfoVote';
import InfoAlert from './InfoAlert';
import ElencoVotazioni from './sceltaVotazione';
import {useLocation} from "react-router-dom";

function VotazioniDisponibili() {
  const results=InfoVote();
  const location = useLocation();

  const email = location.state.email;

  if(results.votazioni){ 
    return (
      <div>
        {/* tabella scheda elettorale */}
        <SchedaElettorale email={email} />
        
        {/*tabella elenco votazioni*/}
        <ElencoVotazioni results={results}/> 

      </div>
    )
  }
   else{
    return(
      <InfoAlert text = 'Non ci sono votazioni' />
    )
  }
}

export default VotazioniDisponibili;