import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root:{
      width: '100%',
      height:'100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position:'center',
      flexDirection:'row',
      marginTop:40,
    },
    DialogTitle: {
        flexGrow: 1,
        textAlign: 'center',
        fontFamily:'"Segoe UI"',
        fontWeight:'Regular',
        fontSize: 15,
        lineHeight:'2',
        },
    dialog:{
        background: '#eeeeee',
        justifyContent:'center',
    },
    ButtonRegistration:{
        marginTop:20,
        marginBottom: 15,
        marginRight: 15,
        backgroundColor: '#eeeeee',
        lineHeight: 1.5,
        fontFamily:'"Segoe UI"',
        fontSize:16,
        width:'150px',
        height:'100px',
    },

    ButtonVote: {
        marginTop:20,
        marginBottom: 15,
        marginLeft: 15,
        backgroundColor: '#eeeeee',
        lineHeight: 1.5,
        fontFamily:'"Segoe UI"',
        fontSize:16,
        width:'150px',
        height:'100px',   
    },

    input: {
        display: 'none',
    },
    
    button:{
        marginTop:10,
        marginBottom: 15,
        backgroundColor: '#eeeeee',
        lineHeight: 1.5,
        fontFamily:'"Segoe UI"',
        fontSize:12,
        width:'100px',
        height:'50px',
    },
  }
);

export default useStyles