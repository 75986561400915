import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles/footerBarTheme';



function FooterBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appbar}>
        <Toolbar>
            <Typography className={classes.text}>
                La registrazione della tessera elettorale prevede di utilizzare un cookie di funzionamento per il login con Unina. <a href="/privacy" target="_blank">Privacy Policy</a>
            </Typography>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default FooterBar