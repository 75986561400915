import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root:{
      width: '100%',
      height:'100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position:'center',
      flexDirection:'column',
      marginTop:40,
    },

    title: {
        flexGrow: 1,
        textAlign: 'center',
        fontFamily:'"Segoe UI"',
        fontWeight:'500',
        fontSize: 40,
        lineHeight:'2',
    },
});

export default useStyles