import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignSelf: 'center',
      marginTop:20,
    },
    table:{
      width: '100%',
      alignItems:'center',  
    },
    tableHead:{
      background:'#eeeeee',
      alignItems:'center',
    },
    tableCell:{
      width: '80%',
    },
    Paper:{
      width: '80%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      flexGrow: 1,
      fontFamily:'"Segoe UI"',
      fontWeight:'Regular',
      fontSize: 20,
      lineHeight:'1',
    },
    text:{
      fontFamily:'"Segoe UI"',
      fontWeight:'Regular', 
      fontSize: 16,
    },
    ButtonStyle:{
        marginTop:20,
        marginBottom: 15,
        padding: '12px 20px',
        backgroundColor: '#eeeeee',
        lineHeight: 1.5,
        fontFamily:'"Segoe UI"',
        fontSize:16,
        width:'180px',
        height:'70px',
    },
  });

export default useStyles