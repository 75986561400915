import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useHistory, useLocation } from 'react-router';
import useStyles  from '../styles/creazioneTesseraTheme';
import NewTessera from './newTessera';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InfoAlert from '../vote_components/InfoAlert';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import GetAppIcon from '@material-ui/icons/GetApp';
import chirotonia from '../../scripts/chirotonia.js';

function CreazioneTessera() {
  const location = useLocation();

  const email = location.state.email;

  let history = useHistory();

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(1);
  const [password, setPassword] = useState('');
  const [confermaPassword, setConfermaPassword] = useState('');
  const [tessera, setTessera] = useState({});
  const [open, setOpen] = useState(false);
  const [openStorage, setOpenStorage] = useState (false);

  function getSteps() {
    return ['Autenticazione', 'Password', 'Generazione tessera elettorale', 'Download tessera elettorale', 'Registrazione avvenuta'];
  }

  const steps = getSteps();

  function handleClick(){
    if(password.length > 5){
      if(password === confermaPassword){
        nextStep();
      }
      else{
          setOpen(true);
      }
    }
  }

  function nextStep(){
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }

  function resetPassword(){
    setPassword(null);
    setConfermaPassword(null);
  }

  function handleReset(){
    document.getElementById('password').value='';
    setPassword('');
    document.getElementById('confermaPassword').value='';
    setConfermaPassword('');
    setOpen(false);
  }

  function saveTessera(tessera){
    setTessera(tessera);
  }

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return 'Ti sei autenticato';
      
      case 1:
        return(
            <div className={classes.textRoot}>
              <TextField className={classes.TextField}
                      autoFocus
                      margin="dense"
                      id="password"
                      label="Inserisci password tessera elettorale"
                      type="password"
                      variant='outlined'
                      onChange={(e) => setPassword(e.target.value)}
                      required
                      size='medium'
                      helperText='Lunghezza minima 6 caratteri'
              />
              <TextField className={classes.TextField}
                      margin="dense"
                      id="confermaPassword"
                      label="Conferma password"
                      type="password"
                      variant='outlined'
                      onChange={(e) => setConfermaPassword(e.target.value)}
                      required
                      size='medium'
                      helperText="Non dimenticare la password. E' necessaria per votare."
              />
                  <Button className={classes.Button} 
                    variant='contained' 
                    onClick={handleClick}
                    disabled={password.length < 6}>
                      Conferma password
                  </Button> 
                  <Dialog open={open}
                          onClose={()=>{setOpen(false)}}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description">
                      <DialogTitle id="alert-dialog-title" className={classes.dialog} >
                          <Typography className={classes.TitleDialog}>Errore</Typography>
                      </DialogTitle>
                      <DialogContent className={classes.dialog}>
                          <Typography  className={classes.text}> Attenzione le password non coincidono. Inserire nuovamente.</Typography>
                      </DialogContent>
                      <DialogActions className={classes.dialog}>
                          <Button onClick={handleReset} 
                                  variant="contained" 
                                  startIcon={<ArrowLeftIcon/> } 
                                  className={classes.ButtonAlert}>
                              Torna indietro
                          </Button>
                      </DialogActions>
                  </Dialog>
            </div>
        );
        
      case 2:
          return(
            <NewTessera email={email} password={password} nextStep={nextStep} resetPassword={resetPassword} saveTessera={saveTessera}></NewTessera>
          )
          

      case 3:
          return(
            <div className={classes.root}>
              <Typography className={classes.textSuccess}>
                Tessera elettorale creata con successo!
              </Typography>
              <Typography className={classes.textSuccess}>
                Clicca sul pulsante per effettuare il download.
              </Typography>
              <Button className={classes.Button} onClick={() => {
                const element = document.createElement("a");
                const file = new Blob([tessera], {type: 'text/json'});
                element.href = URL.createObjectURL(file);
                element.download = "tessera.json";
                document.body.appendChild(element); 
                element.click();
                document.body.removeChild(element);
                setOpenStorage(true);}}
                variant="contained"
                endIcon={<GetAppIcon></GetAppIcon>} 
              >
                  Download
              </Button>
              <Dialog open={openStorage}
                          onClose={()=>{setOpenStorage(false)}}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description">
                      <DialogTitle id="alert-dialog-title" className={classes.dialog} >
                          <Typography className={classes.TitleDialog}>Vuoi memorizzare la tessera elettorale nel browser?</Typography>
                      </DialogTitle>
                      <DialogContent className={classes.dialog}>
                          <Typography  className={classes.text}> Scegliere di memorizzarla solo se non si usano dispositivi condivisi.</Typography>
                      </DialogContent>
                      <DialogActions className={classes.dialog}>
                          <Button onClick={() => {
                                  setOpenStorage(false);
                                  nextStep();}} 
                                  variant="contained" 
                                  className={classes.ButtonStyleLeft}>
                              No
                          </Button>
                          <Button onClick={() => {
                            try{
                              let tesseraElettorale = chirotonia.TesseraElettorale.initFromJSON(tessera);
                              tesseraElettorale.toLocalStorage();
                              setOpenStorage(false);
                              nextStep();
                            } catch (error) {
                              return(
                                <InfoAlert text={"Errore"}> </InfoAlert>
                              );
                          }}} 
                                  variant="contained" 
                                  className={classes.ButtonStyleRight}>
                              Si
                          </Button>
                      </DialogActions>
                  </Dialog>
            </div>
          )
        
        case 4:
          nextStep();
          
        case 5:
          return(
            <div className={classes.root}>
              <Typography className={classes.textSuccess}>
                Registrazione della tessera avvenuta correttamente.
              </Typography>
              <Button className={classes.Button}
                      variant="contained" 
                      onClick={()=>{ 
                        history.push('/');
                      }}
              >
                Torna alla Home
              </Button>
              </div>
          )

        }
  }

  return (
    <div className={classes.root}>
    <Stepper className={classes.Stepper} activeStep={activeStep} alternativeLabel>
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel >
          <Typography className={classes.text}>
              {label}
          </Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
    {getStepContent(activeStep)}
    </div>
  );
  
}

export default CreazioneTessera
