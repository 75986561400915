import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    rootSchedaVotazione: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop:20,
    },

    table:{
      width: '100%',
      alignItems:'center',
      
    },
    tableHead:{
      background:'#eeeeee',
      alignItems:'center',
    },
    tableCell:{
      width: '80%',
    },
    ButtonStyle:{
      marginTop:20,
      marginBottom: 15,
      padding: '12px 20px',
      backgroundColor: '#eeeeee',
      lineHeight: 1.5,
      fontFamily:'"Segoe UI"',
      fontSize:16,
      width:'170px',
      height:'60px',
    },

    PaperSchedaVotazione:{
        width: '70%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    titleSchedaVotazione: {
      flexGrow: 1,
      fontFamily:'"Segoe UI"',
      fontWeight:'500',
      fontSize: 17,
      lineHeight:'1',
    },

    text:{
      fontFamily:'"Segoe UI"',
      fontWeight:'Regular', 
      fontSize: 20,
    },
    
    textSchedaVotazioneCampi:{
      fontFamily:'"Segoe UI"',
      fontWeight:'500', 
      fontSize: 15,
    },

    textSchedaVotazione:{
      fontFamily:'"Segoe UI"',
      fontWeight:'Regular', 
      fontSize: 15,
    },
});

export default useStyles