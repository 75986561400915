import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      flexDirection: 'column',
    },
  
    stepper: {
      width: '100%',
    },
    
    title: {
      fontFamily:'"Segoe UI"',
      fontWeight:'500', 
      fontSize: 20,
    },

    text:{
      fontFamily:'"Segoe UI"',
      fontWeight:'Regular', 
      fontSize: 16,
    },

    textEsito:{
      fontFamily:'"Segoe UI"',
      fontWeight:'500', 
      fontSize: 16,
    },

    buttonRoot:{
        display: 'flex',
        justifyContent: 'space-between',
        alignSelf: 'center',
        marginTop:20,
        flexDirection: 'row',
    },

    rootEsito:{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '70%',
      flexDirection: 'column',
    },
  
    ButtonStyle: {
      padding: '12px 20px',
      backgroundColor: '#eeeeee',
      lineHeight: 1.5,
      fontFamily:'"Segoe UI"',
      fontSize:16,
    },

    ButtonLeft:{
      marginTop:20,
      marginBottom: 15,
      marginRight: 15,
      backgroundColor: '#eeeeee',
      lineHeight: 1.5,
      fontFamily:'"Segoe UI"',
      fontSize:16,
      width:'150px',
      height:'80px',
  },

  ButtonRight: {
      marginTop:20,
      marginBottom: 15,
      marginLeft: 15,
      backgroundColor: '#eeeeee',
      lineHeight: 1.5,
      fontFamily:'"Segoe UI"',
      fontSize:16,
      width:'150px',
      height:'80px',   
  },
  
}));

  export default useStyles