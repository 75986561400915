import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    appbar:{
      background: '#13426b',
      border:0,
      margin:0,
      padding:0,
      top:0,
    },
    title: {
      flexGrow: 1,
      textAlign: 'center',
      fontFamily:'"Segoe UI"',
      fontWeight:'500',
      fontSize: 32,
      letterSpacing: '4px',
      lineHeight:'2',
      },
}));

export default useStyles