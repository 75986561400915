import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import React, {useState} from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { Button } from '@material-ui/core';
import { useHistory} from "react-router-dom";
import useStyles from '../styles/sceltaVotazioniTheme';

function ElencoVotazioni(props){

    const results= props.results;
    const [value, setValue] = useState(null)

    let history = useHistory();

    function handleChange(event) {
        if (event.target.value === value) {
            setValue(null);
        } 
        else { 
            setValue(event.target.value);
        }
    }

    function getCandidates(){
        for(var i=0; i<results.votazioni.length; i++){
            if(results.votazioni[i].id === value){
                return i;
            }
        }
    }

    function handleClick(){
        if(value !== null){
            history.push("/voting/candidates", {results: results.votazioni[getCandidates()], voteId: value})
        }
    }

    const classes = useStyles();

    return(
        <div>
            <div className={classes.root}>
                <TableContainer className={classes.Paper} component={Paper}>
                    <Table className={classes.table} >
                    <TableHead >
                        <TableRow className={classes.tableHead}>
                            <TableCell colSpan={2}>
                                <Typography className={classes.title}>
                                    Votazioni
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {results.votazioni && results.votazioni.map(votazione =>  
                            <TableRow key={votazione.id}>
                                <TableCell >
                                    <RadioGroup value={value}>
                                        <FormControlLabel 
                                            value = {votazione.id} 
                                            control={<Radio onClick={handleChange} color="primary"/>} />  
                                    </RadioGroup>
                                </TableCell>
                                <TableCell> 
                                    <Typography className={classes.text}>
                                        {votazione.title}
                                    </Typography> 
                                </TableCell>
                            </TableRow>          
                        )}
                    </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div className={classes.root}>            
                <Button className={classes.ButtonStyle} 
                variant="contained" 
                color="default" 
                size="large" 
                onClick={handleClick} 
                >
                Visualizza candidati
                </Button> 
            </div> 
    </div>
            
    )
}

export default ElencoVotazioni