import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import logoUnina from '../images/logoUnina.png';
import useStyles from './styles/headerBarTheme';



function HeaderBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="sticky" className={classes.appbar}>
      
        <Toolbar>
          <img src={logoUnina} alt="logoUnina" width="30%" />
        </Toolbar>

      </AppBar>
    </div>
  );
}

export default HeaderBar