import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      flexDirection: 'column',
    },
  
    stepper: {
      width: '80%',
    },
  
    textRoot: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      flexDirection: 'column',
      marginTop:20,
    },
  
    TextField: {
      width:'300px',
      marginTop: 24,
    },
  
    title:{
      fontFamily:'"Segoe UI"',
      fontWeight:'regular', 
      fontSize: 24,
    },
  
    text:{
      fontFamily:'"Segoe UI"',
      fontWeight:'Regular', 
      fontSize: 16,
    },
  
    Button:{
      marginTop:20,
      marginBottom: 15,
      backgroundColor: '#eeeeee',
      lineHeight: 1.5,
      fontFamily:'"Segoe UI"',
      fontSize:16,
      width:'170px',
      height:'60px',
    },

    dialog:{
      background: '#eeeeee',
  },

  TitleDialog:{
    fontFamily:'"Segoe UI"',
    fontWeight:'500', 
    fontSize: 18,
  },

  ButtonAlert:{
    marginTop:20,
    marginBottom: 15,
    backgroundColor: '#eeeeee',
    lineHeight: 1.5,
    fontFamily:'"Segoe UI"',
    fontSize:16,
  },

  rootDialog:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  textSuccess:{
    fontFamily:'"Segoe UI"',
    fontWeight:'500', 
    fontSize: 24,
  },

  ButtonStyleRight:{
    marginTop:20,
    marginBottom: 15,
    padding: '12px 20px',
    backgroundColor: '#eeeeee',
    lineHeight: 1.5,
    fontFamily:'"Segoe UI"',
    fontSize:16,
    width:'170px',
    height:'60px',
    float: 'right',
},
ButtonStyleLeft:{
    marginTop:20,
    marginBottom: 15,
    padding: '12px 20px',
    backgroundColor: '#eeeeee',
    lineHeight: 1.5,
    fontFamily:'"Segoe UI"',
    fontSize:16,
    width:'170px',
    height:'60px',
    float: 'left',
},

  }));

  export default useStyles