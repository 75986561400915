import ec from './alt_bn128'
import EventEmitter from './utils';
import LinkableRingSignatureSchema from './ring';
import { BN } from 'bn.js';
import endpoints from './endpoint';

const VOTE_EVENT_PKEYS_FETCHED = 'pkeys_fetched';
const VOTE_EVENT_SIG_CREATED = 'sig_created';
const VOTE_EVENT_SIG_VERIFIED = 'sig_verified';
const VOTE_EVENT_BALLOT_SENT = 'ballot_sent';
const VOTE_EVENT_BALLOT_RESPONSE = 'ballot_response';
const VOTE_EVENT_ERROR = 'vote_error';

const VOTE_ERROR_UNREGISTERED = {
    reason: 'unregistered',
    message: "Votante non registrato per la votazione"
};
const VOTE_ERROR_PASSWORD = {
    reason: 'wrong_password',
    message: "Password errata per lo sblocco della tessera elettorale"
};
const VOTE_ERROR_REJECTED = {
    reason: 'rejected',
    message: "Il voto è stato rifiutato dal sistema"
};
const VOTE_ERROR_NETWORK = {
    reason: 'network_error',
    message: "Errore di comunicazione con il server"
}
const VOTE_ERROR_SIGNATURE = {
    reason: 'signature',
    message: "Errore nel calcolo della firma. Riprova."
}
const VOTE_ERROR_BALLOT = {
    reason: 'ballot',
    message: "Errore nella creazione del voto. Riprova."
}


class TesseraElettorale {

    static PBKDF2_ITERATIONS = 100000;

    /**
     * L'email UNINA che identifica il votante
     * @param {string} email 
     */
    constructor(email) {
        this.email = email;
        this.textEncoder = new TextEncoder();
    }

    /**
     * La password con cui criptare la chiave privata di voto
     * @param {string} password 
     */
    async init(password) {
        let keypair = ec.genKeyPair();
        this.privateKey = keypair.getPrivate()
        await this.encryptPrivateKey(password);
        this.publicKey = keypair.getPublic();
        let challengeDataHash = new Uint8Array(
            await window.crypto.subtle.digest(
                "SHA-256",
                this.textEncoder.encode(this.email + '|EC|alt_bn128')
        ));
        this.proof = keypair.sign(challengeDataHash);
    }

    /**
     * Inizializza la tessera elettorale importando i dati da un JSON
     * @param {string} json 
     * @returns {TesseraElettorale}
     */
    static initFromJSON(json) {
        let data = JSON.parse(json);
        let tessera = new TesseraElettorale(data.email);
        tessera.email = data.email;
        tessera.publicKey = ec.keyFromPublic({
            x: data.voting.publicKey.point.x,
            y: data.voting.publicKey.point.y
        }).getPublic();
        const ivKeys = Object.keys(data.voting.privateKey.d.iv);
        let iv = new Uint8Array(new ArrayBuffer(ivKeys.length));
        ivKeys.forEach(key => {
            iv[parseInt(key)] = data.voting.privateKey.d.iv[key];
        });
        const ciphertextKeys = Object.keys(data.voting.privateKey.d.ciphertext);
        let ciphertext = new Uint8Array(new ArrayBuffer(ciphertextKeys.length));
        ciphertextKeys.forEach(key => {
            ciphertext[parseInt(key)] = data.voting.privateKey.d.ciphertext[key];
        });
        const saltKeys = Object.keys(data.voting.privateKey.d.salt);
        let salt = new Uint8Array(new ArrayBuffer(saltKeys.length));
        saltKeys.forEach(key => {
            salt[parseInt(key)] = data.voting.privateKey.d.salt[key];
        });
        tessera.privateKey = {
            iv: iv,
            ciphertext: ciphertext,
            salt: salt
        };
        tessera.proof = data.voting.proof;
        return tessera
    }

    /**
     * Ripristina una tessera elettorale dal local storage del browser
     * @returns {TesseraElettorale}
     */
    static fromLocalStorage() {
        if (typeof localStorage.tesseraElettorale !== "string") {
            throw new Error("Tessera elettorale non presente nel local storage del browser");
        }
        return TesseraElettorale.initFromJSON(localStorage.tesseraElettorale);
    }

    /**
     * Esporta la TesseraElettorale come JSON
     * @param {boolean} exportPrivate 
     * @returns {string}
     */
    toJSON(exportPrivate) {
        let toExport = {
            email: this.email,
            voting: {
                publicKey: {
                    type: "EC",
                    curve: "alt_bn128",
                    point: {
                        x: this.publicKey.getX(),
                        y: this.publicKey.getY()
                    }
                },
                proof: this.proof
            }
        };
        if (exportPrivate) {
            toExport.voting.privateKey = {
                type: "EC",
                curve: "alt_bn128",
                d: {
                    iv: this.privateKey.iv,
                    ciphertext: this.privateKey.ciphertext,
                    salt: this.privateKey.salt
                }
            };
        }
        return JSON.stringify(toExport);
    }

    /**
     * Salva la tessera elettorale nel local storage del browser
     */
    toLocalStorage() {
        localStorage.tesseraElettorale = this.toJSON(true);
        console.log('Tessera elettorale per ' + this.email + ' salvata nel browser.');
    }

    /**
     * 
     * @param {CryptoKey} encryptionKey 
     * @param {BN} privateKey 
     * @returns 
     */
    async encryptPrivateKey(password) {
        if (this.privateKey.iv) {
            throw new Error("Private key already encrypted");
        }
        let keyMaterial = await deriveKeyFromPassword(password, TesseraElettorale.PBKDF2_ITERATIONS);
        let encrypted = {
            iv: window.crypto.getRandomValues(new Uint8Array(12)),
            salt: keyMaterial.salt
        }
        encrypted.ciphertext = new Uint8Array(await crypto.subtle.encrypt(
            {
                name: 'AES-GCM',
                iv: encrypted.iv
            },
            keyMaterial.key,
            this.privateKey.toArrayLike(Uint8Array, 'be', 32)
        ));
        this.privateKey = encrypted;
    }

    /**
     * 
     * @param {CryptoKey} decryptionKey 
     * @returns 
     */
    async getPrivateKey(password) {
        let keyMaterial = await deriveKeyFromPassword(password, TesseraElettorale.PBKDF2_ITERATIONS, this.privateKey.salt);
        let decrypted = await crypto.subtle.decrypt({
            name: 'AES-GCM',
            iv: this.privateKey.iv
        },
        keyMaterial.key,
        this.privateKey.ciphertext);
        return ec.keyFromPrivate(new Uint8Array(decrypted)).getPrivate();
    }

    async generateVoteSignalingPayload(password) {
        let kp = ec.keyFromPrivate(await this.getPrivateKey(password));
        let challengeDataHash = new Uint8Array(
            await window.crypto.subtle.digest(
                "SHA-256",
                this.textEncoder.encode(this.email + "|EC|alt_bn128")
        ));
        return {
            email: this.email,
            pk: {
                type: "EC",
                curve: "alt_bn128",
                point: {
                    x: this.publicKey.getX(),
                    y: this.publicKey.getY()
                }
            },
            signature: kp.sign(challengeDataHash)
        }
    }
}

/**
 * 
 * @param {TesseraElettorale} tessera 
 * @param {string} password 
 * @param {string} voteId 
 * @param {string} idScelta 
 * @returns 
 */
function vote(tessera, password, voteId, idScelta) {
    let eventEmitter = new EventEmitter();
    // Decripta la chiave privata
    tessera.getPrivateKey(password).then(sk => {
        console.log("Chiave privata decriptata con successo");
        // Recupera informazioni di voto
        fetch(`${endpoints.ENDPOINT_VOTE}/${voteId}`).then(votazioneResponse => {
            let pkeys = [];
            let pkeysStringX = [];
            votazioneResponse.json().then(votazione => {
                for (let index = 0; index < votazione.rings.length; index++) {
                    const voteGroup = votazione.rings[index];
                    let pkeysGroup = [];
                    let pkeysGroupStringX = [];
                    voteGroup.forEach(voter => {
                        let pk = ec.keyFromPublic(voter.pk, 'hex').getPublic();
                        pkeysGroup.push(pk);
                        pkeysGroupStringX.push(pk.getX().toString());
                    });
                    pkeys.push(pkeysGroup);
                    pkeysStringX.push(pkeysGroupStringX);
                }
                let signerIndex = -1;
                let schemaIndex = -1;
                for (let index = 0; index < pkeys.length; index++) {
                    const pkeysGroup = pkeysStringX[index];
                    signerIndex = pkeysGroup.indexOf(tessera.publicKey.getX().toString());
                    if (signerIndex !== -1) {
                        schemaIndex = index;
                        break
                    }
                }
                if (signerIndex === -1) {
                    eventEmitter.fire(VOTE_EVENT_ERROR, VOTE_ERROR_UNREGISTERED);
                    return;
                }
                let linkableSigSchema = new LinkableRingSignatureSchema(pkeys[schemaIndex]);
                console.log("Dati votazione elaborati con successo");
                eventEmitter.fire(VOTE_EVENT_PKEYS_FETCHED);
                // Cripta la scelta con la pk della votazione
                encryptChoice(idScelta, votazione.encPK, voteId).then(encChoice => {
                    console.log("Voto criptato con successo");
                    window.crypto.subtle.digest("SHA-256", encChoice).then(encVoteHash => {
                        let encVoteHashBN = new BN(new Uint8Array(encVoteHash));
                        // Calcola la firma ad anello
                        let lrs = linkableSigSchema.sign(sk, signerIndex, encVoteHashBN);
                        eventEmitter.fire(VOTE_EVENT_SIG_CREATED);
                        console.log("Firma ad anello generata con successo");
                        // Genera payload di segnalamento voto (richiesto dalla commissione elettorale anche se non necessario)
                        let signalingPayloadPromise = tessera.generateVoteSignalingPayload(password);
                        // Verifica la firma ad anello
                        // if (linkableSigSchema.verify(lrs.tees, lrs.anchor, lrs.tag, lrs.message)) {
                        eventEmitter.fire(VOTE_EVENT_SIG_VERIFIED);
                        lrs.tag = {
                            x: lrs.tag.getX(),
                            y: lrs.tag.getY()
                        };
                        fetch(`${endpoints.ENDPOINT_VOTE}/${voteId}/${schemaIndex}`, {
                            method: "POST",
                            cache: "no-cache",
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                signature: lrs,
                                choice: new Uint8Array(encChoice)
                            })
                        }).then(voteSubmissionResult => {
                            if (voteSubmissionResult.status === 200) {
                                voteSubmissionResult.json().then(response => {
                                    console.log(response);
                                    signalingPayloadPromise.then(payload => {
                                        fetch(`${endpoints.ENDPOINT_VOTED}/${voteId}`, {
                                            method: "POST",
                                            cache: "no-cache",
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify(payload)
                                        }).then(result => {
                                            console.log(result);
                                        }).catch(error => {
                                            console.error(error);
                                        });
                                    });
                                    eventEmitter.fire(VOTE_EVENT_BALLOT_RESPONSE, response);
                                }).catch(error => {
                                    console.error("Error with body parsing at POST " + endpoints.ENDPOINT_VOTE);
                                    console.error(error)
                                    eventEmitter.fire(VOTE_EVENT_ERROR, VOTE_ERROR_NETWORK);
                                });
                            } else {
                                voteSubmissionResult.json().then(response => {
                                    response.reason = VOTE_ERROR_REJECTED.reason;
                                    eventEmitter.fire(VOTE_EVENT_ERROR, response);
                                    console.log(response);
                                }).catch(error => {
                                    console.error("Error with body parsing at POST " + endpoints.ENDPOINT_VOTE);
                                    console.error(error)
                                    eventEmitter.fire(VOTE_EVENT_ERROR, VOTE_ERROR_NETWORK);
                                });
                            }
                        }).catch(error => {
                            console.error("Error with POST " + endpoints.ENDPOINT_VOTE);
                            console.error(error)
                            eventEmitter.fire(VOTE_EVENT_ERROR, VOTE_ERROR_NETWORK);
                        });
                        eventEmitter.fire(VOTE_EVENT_BALLOT_SENT);
                    }).catch(error => {
                        console.error("Error in choice hashing");
                        console.error(error);
                        eventEmitter.fire(VOTE_EVENT_ERROR, VOTE_ERROR_SIGNATURE);
                    });
                });
            }).catch(error => {
                console.error("Can't encrypt vote choice");
                console.error(error);
                eventEmitter.fire(VOTE_EVENT_ERROR, VOTE_ERROR_BALLOT);
            });
        }).catch(error => {
            console.error("Error with GET " + endpoints.ENDPOINT_VOTE);
            console.error(error)
            eventEmitter.fire(VOTE_EVENT_ERROR, VOTE_ERROR_NETWORK);
        });
    }).catch(error => {
        console.error("Can't decrypt private key necessary for voting");
        console.error(error);
        eventEmitter.fire(VOTE_EVENT_ERROR, VOTE_ERROR_PASSWORD);
    })
    return eventEmitter;
}

/**
 * 
 * @param {string} password 
 * @param {number} iterations
 * @returns 
 */
async function deriveKeyFromPassword(password, iterations, salt) {
    let textEncoder = new TextEncoder();
    let passwordKey = await window.crypto.subtle.importKey(
        "raw",
        textEncoder.encode(password),
        "PBKDF2",
        false,
        ["deriveKey"]
    );
    if (!salt) {
        salt = window.crypto.getRandomValues(new Uint8Array(16));
    }
    let cryptoKey = await window.crypto.subtle.deriveKey(
        {
            name: "PBKDF2",
            hash: "SHA-256",
            salt: salt,
            iterations: iterations
        },
        passwordKey,
        {
            name: "AES-GCM",
            length: 256
        },
        false,
        ["encrypt", "decrypt"]
    )
    return Promise.resolve({
        key: cryptoKey,
        salt: salt
    });
}

/**
 * 
 * @param {string} str 
 * @returns {ArrayBuffer}
 */
 function str2ab(str) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
      bufView[i] = str.charCodeAt(i);
    }
    return buf;
}

/**
 * 
 * @param {string} choice 
 * @param {string} publicKey
 * @param {string} voteId
 * @returns {Promise<ArrayBuffer>}
 */
async function encryptChoice(choice, publicKey, voteId) {
    let textEncoder = new TextEncoder();
    try {
        let key = await importPublicKey(publicKey);
        return window.crypto.subtle.encrypt(
            {
                name: "RSA-OAEP",
                label: textEncoder.encode(voteId)
            },
            key,
            textEncoder.encode(choice)
        ) 
    } catch (error) {
        return Promise.reject(error);
    }
}

/**
 * 
 * @param {string} pem 
 * @returns {Promise<CryptoKey>}
 */
function importPublicKey(pem) {
    // base64 decode the string to get the binary data
    const binaryDerString = window.atob(pem);
    // convert from a binary string to an ArrayBuffer
    const binaryDer = str2ab(binaryDerString);
    return window.crypto.subtle.importKey(
      "spki",
      binaryDer,
      {
        name: "RSA-OAEP",
        hash: "SHA-256",
      },
      true,
      ["encrypt"]
    );
}

let chirotonia = {
    VOTE_EVENT_SIG_CREATED,
    VOTE_EVENT_SIG_VERIFIED,
    VOTE_EVENT_BALLOT_SENT,
    VOTE_EVENT_BALLOT_RESPONSE,
    VOTE_EVENT_ERROR,
    VOTE_ERROR_UNREGISTERED,
    VOTE_ERROR_SIGNATURE,
    VOTE_ERROR_NETWORK,
    VOTE_ERROR_PASSWORD,
    VOTE_ERROR_REJECTED,
    VOTE_ERROR_BALLOT,
    TesseraElettorale,
    vote
};

export default chirotonia