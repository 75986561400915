import React from 'react';
import {useEffect, useState} from 'react';
import chirotonia from '../../scripts/chirotonia';
import { useLocation, useHistory} from 'react-router-dom';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import InfoAlertQR from './infoAlertQr';
import useStyles from '../styles/voteRegistrationTheme';
import AlertErrorPassword from './alertErrorPassword';
import InfoAlert from '../vote_components/InfoAlert';
import { Button } from '@material-ui/core';
import QRCode from 'qrcode.react';



function VoteRegistration(){

    const location = useLocation();
    const password = location.state.password;
    const idScelta = location.state.idScelta;
    const voteId = location.state.voteId;
    let history = useHistory();

    const classes = useStyles();
    const [response, setResponse] = useState({});
    const [error, setError] = useState({});
    const [activeStep, setActiveStep] = useState(0);
    const steps = getSteps();

    function getSteps() {
        return ['Generazione firma', 'Invio voto', 'Esito'];
    }

    function nextStep(){
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    function errorStep(){
      setActiveStep('error');
    }

    function vote(){
      let regEvents = chirotonia.vote(chirotonia.TesseraElettorale.fromLocalStorage(), password, voteId, idScelta);
      regEvents.on(chirotonia.VOTE_EVENT_SIG_VERIFIED, (data) => {
          nextStep();
      }).on(chirotonia.VOTE_EVENT_BALLOT_SENT, (data) => {
          nextStep();
      }).on(chirotonia.VOTE_EVENT_BALLOT_RESPONSE, (data) => {
          setResponse(data);
          nextStep();
      }).on(chirotonia.VOTE_EVENT_ERROR, (data) => {  
          setError(data);
          errorStep();
      })
    }

    useEffect(()=>{
      vote();
    }, [])


    function downloadQRCode(){
        const qrCodeURL = document.getElementById('qrCodeEl')
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        let aEl = document.createElement("a");
        aEl.href = qrCodeURL;
        aEl.download = "QRCode_Traceability.png";
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);
    }

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
              return(
                <Typography className={classes.title}>
                Generazione firma ad anello in corso...
                </Typography>
              )     
            case 1:
              return(
                <Typography className={classes.title}>
                Invio voto in corso...
                </Typography>
              )  
            case 2:
              return(
                <Typography className={classes.title}>
                Esito votazione...
                </Typography>
              )
            case 3:
              if(response){
                return(         
                  <div className={classes.rootEsito}>
                      <Typography className={classes.title}>
                      Esito votazione:
                      </Typography>
                      <br></br>
                      <Typography className={classes.textEsito}>
                      {response.message}
                      </Typography>
                      <br></br>
                      <Typography className={classes.text}>
                      <a href={response.traceability} target="_blank">Questa ricevuta</a> dimostra che il voto è stato correttamente processato dalla blockchain, la quale ne ha accettato la firma ad anello collegabile e l'ha
                      immutabilmente salvato tra i voti per lo scrutinio.
                      </Typography>
                      <br></br>
                      <QRCode
                          id="qrCodeEl"
                          size={150}
                          value={response.traceability}
                      />
                      <div className={classes.buttonRoot}>
                         <Button className={classes.ButtonLeft}
                         variant="contained"
                         onClick={()=>{history.push('/')}}>
                             Torna alla home
                         </Button>
                         <Button className={classes.ButtonRight}
                         variant="contained"
                         onClick={downloadQRCode}>
                             Download QRcode
                         </Button>
                      </div>
                  </div>
                )
            }
            case 'error':
                if(error.reason === chirotonia.VOTE_ERROR_UNREGISTERED.reason){
                    return(
                      <InfoAlert text={error.message}></InfoAlert>
                    )
                  }
                if(error.reason === chirotonia.VOTE_ERROR_PASSWORD.reason){
                    return(
                      <AlertErrorPassword text={error.message}></AlertErrorPassword>
                    )
                }
                if(error.reason === chirotonia.VOTE_ERROR_REJECTED.reason){
                    return(
                      <InfoAlertQR error={error}></InfoAlertQR>
                    )
                }
                if(error.reason === chirotonia.VOTE_ERROR_NETWORK.reason){
                    return(
                      <InfoAlert text={error.message}></InfoAlert>
                    )
                }
                if(error.reason === chirotonia.VOTE_ERROR_SIGNATURE.reason){
                    return(
                      <InfoAlert text={error.message}></InfoAlert>
                    )
                }
                if(error.reason === chirotonia.VOTE_ERROR_BALLOT.reason){
                    return(
                      <InfoAlert text={error.message}></InfoAlert>
                    )
                }
          }
    }
    
      return (
        <div className={classes.root}>
          <Stepper className={classes.Stepper} activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel >
                <Typography className={classes.text}>
                    {label}
                </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {getStepContent(activeStep)}
        </div>
      );
    }

export default VoteRegistration;