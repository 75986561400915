import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import useStyles from '../styles/schedaVotazioneTheme';

function SchedaVotazione(props) {
    
    const results= props.results;

    const classes = useStyles();

    return (
      <div className={classes.rootSchedaVotazione}> {/*tabella scheda votazione*/}
        <TableContainer className={classes.PaperSchedaVotazione} component={Paper}>
          <Table className={classes.table} >
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell colSpan={2}> 
                  <Typography className={classes.titleSchedaVotazione}> Info votazione </Typography> 
                </TableCell> 
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography className={classes.textSchedaVotazioneCampi}> Quesito </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.textSchedaVotazione}> {results.subject} </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography className={classes.textSchedaVotazioneCampi}> Data inizio votazione </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.textSchedaVotazione}> 
                  {moment.utc(results.voteStart).isAfter(moment.parseZone().utc('true'), 'minute') ? 
                   moment.utc(results.voteStart).local().format('LLL')+' - La votazione non è ancora iniziata' : moment.utc(results.voteStart).local().format('LLL')} 
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography className={classes.textSchedaVotazioneCampi}> Data fine votazione </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.textSchedaVotazione}> {moment.utc(results.voteEnd).local().format('LLL')} </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
         </TableContainer>
       </div>
    )
}

export default SchedaVotazione;
