import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop:20,
        flexDirection: 'column',
        background: '#eeeeee',
      },

      alert:{
        background: '#eeeeee',
        width:'100%',
        paddingLeft:50,
        paddingRight:50,
      },

      buttonRoot:{
        display: 'flex',
        justifyContent: 'space-between',
        alignSelf: 'center',
        marginTop:20,
        flexDirection: 'row',
    },

      ButtonLeft:{
          marginTop:20,
          marginBottom: 15,
          marginRight: 15,
          backgroundColor: '#eeeeee',
          lineHeight: 1.5,
          fontFamily:'"Segoe UI"',
          fontSize:16,
          width:'150px',
          height:'80px',
      },
      ButtonRight: {
          marginTop:20,
          marginBottom: 15,
          marginLeft: 15,
          backgroundColor: '#eeeeee',
          lineHeight: 1.5,
          fontFamily:'"Segoe UI"',
          fontSize:16,
          width:'150px',
          height:'80px',  
      },
  })
)

export default useStyles