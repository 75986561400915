import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    appbar:{
      backgroundColor: '#eeeeee',
      border:0,
      top:'auto',
      bottom:0,
    },
  }));

export default useStyles