import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    appbar:{
      backgroundColor: '#eeeeee',
      border:0,
      top:'auto',
      bottom:0,
    },
    text: {
      color:'black',
      flexGrow: 1,
      textAlign: 'left',
      fontSize: 14,
      fontFamily:'"Segoe UI"',
      fontWeight:'Regular',
      letterSpacing: '1px',
      lineHeight:'2',
      },
  }));

export default useStyles