import React from 'react';
import Card from '@material-ui/core/Card'
import { Alert, AlertTitle } from '@material-ui/lab';
import {Link, useHistory} from 'react-router-dom';
import useStyles from '../styles/infoAlertQrTheme';
import { Button } from '@material-ui/core';
import QRCode from 'qrcode.react';

function InfoAlertQR(props){

    const error = props.error;
    let history = useHistory();
    
    const classes = useStyles();

    function downloadQRCode(){
        const qrCodeURL = document.getElementById('qrCodeEl')
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        let aEl = document.createElement("a");
        aEl.href = qrCodeURL;
        aEl.download = "QRCode_Traceability.png";
        document.body.appendChild(aEl);
        aEl.click();
        document.body.removeChild(aEl);
    }
    
    if(error.traceability){
        return (
            <Card className={classes.root}>
            <Alert severity="info" className={classes.alert}>
              <AlertTitle>Info</AlertTitle>
                  Attenzione — <strong>{error.message}</strong>
            </Alert>
            <br></br>
            <QRCode
                id="qrCodeEl"
                size={150}
                value={error.traceability}
            />
            <div className={classes.buttonRoot}>
               <Button className={classes.ButtonLeft}
               variant="contained"
               onClick={()=>{history.push('/')}}>
                   Torna alla home
               </Button>
               <Button className={classes.ButtonRight}
               variant="contained"
               onClick={downloadQRCode}>
                   Download QRcode
               </Button>
            </div>
            </Card>
      )
    }
    else{
        return (
            <Card className={classes.root}>
                <Alert severity="info" className={classes.alert}>
                    <AlertTitle>Info</AlertTitle>
                  Attenzione — <strong>{error.message}</strong>
                    <Link to="/">
                    <h4>Torna indietro</h4>         
                    </Link>
                </Alert>
            </Card>
        )
    }
}

export default InfoAlertQR

