var endpoints = {
    ENDPOINT_LOGIN: "/login",
    ENDPOINT_CANDIDATI: "/chirotonia/votes",
    ENDPOINT_VOTER: "/chirotonia/voter",
    ENDPOINT_VOTE: "/chirotonia/vote",
    ENDPOINT_VOTED: "/chirotonia/voted"
}

if (process.env.NODE_ENV === 'development') {
    Object.keys(endpoints).forEach(key => {
        endpoints[key] = "http://localhost" + endpoints[key];
    });
}

export default endpoints