import {useEffect, useState} from 'react';
import endpoint from '../../scripts/endpoint';

function InfoVoter() {
    const [results, setResults] = useState({})
    
    useEffect(()=>{
        getVoter();
    }, [])

    async function getVoter() {
        const response = await fetch(endpoint.ENDPOINT_VOTER)
        const data = await response.json();        
        setResults(data);
        
    }
    return results
}

export default InfoVoter;