import { Alert, AlertTitle } from '@material-ui/lab';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';


function AlertErrorPassword(props){
    const useStyles = makeStyles({
      root: {
        display:'flex',
        justifyContent: 'center',
        alignSelf: 'center',
      },
      alert:{
        background: '#eeeeee',
        width:'100%',
        paddingLeft:50,
        paddingRight:50,
      },
      buttonRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        alignSelf: 'center',
        marginTop:20,
        flexDirection: 'row',
      },
      ButtonStyle: {
        padding: '12px 20px',
        backgroundColor: '#eeeeee',
        lineHeight: 1.5,
        fontFamily:'"Segoe UI"',
        fontSize:16,
      }
    });
    
  const classes = useStyles();
  let history = useHistory();

  return (
    <div className={classes.root}>
      <Alert severity="info" className={classes.alert}>
        <AlertTitle>Info</AlertTitle>
            Attenzione — <strong>{props.text}</strong>
            <br></br>
            Torna indietro per effettuare nuovamente la votazione e inserire nuovamente la password oppure torna alla Home.
            <div className={classes.buttonRoot}>
             <Button className={classes.ButtonStyle}
                    variant="contained"  
                    onClick={()=>{history.goBack()}}
            > Torna indietro
            </Button> 
            <Button className={classes.ButtonStyle}
                    variant="contained" 
                    onClick={() =>{history.push("/")}}
            > Torna alla Home
            </Button>           
            </div>
      </Alert>
      </div>
    )
}

export default AlertErrorPassword

