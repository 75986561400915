import CopyrightIcon from '@material-ui/icons/Copyright';
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography';
import useStyles from './styles/creditsTheme';
import Divider from '@material-ui/core/Divider';
import Chirotonia_DataInBrief from '../documents/Chirotonia_Summary.pdf';
import Chirotonia_guida from '../documents/Chirotonia_guida.pdf';

function Credits(){

    const classes = useStyles();

    return(
        <Card className={classes.root}>
            <CopyrightIcon fontSize='small'></CopyrightIcon>
            <Typography className={classes.text}><b>Design, Implementation, Deployment and Configuration:</b> <a href={'mailto:antonio@antoniorusso.me'}><i>Antonio Russo</i></a>
            </Typography>
            <Divider/>
            <Typography className={classes.text}><b>Supervision:</b> <a href={'mailto:spromano@unina.it'}><i>Simon Pietro Romano</i></a>
            </Typography>
            <Divider/>
            <Typography className={classes.text}><b>Credits:</b> <a href={'mailto:alfonsom.calabria@gmail.com'}> <i>Alfonso Maria Calabria</i></a> <i>{'&'}</i> <a href={'mailto:annapiscitelli1102@gmail.com'}><i>Anna Piscitelli</i></a> (front-end contributors)
            </Typography>
            <Divider/>
            <br></br>
            <div className={classes.rootLink}>
                <Typography className={classes.text}><a href={Chirotonia_DataInBrief} rel="noreferrer" target='_blank'>About Chirotonia</a></Typography>
                <Typography className={classes.text}><a href={Chirotonia_guida} rel="noreferrer" target='_blank'>Guida all'uso</a></Typography>
                <Typography className={classes.text}><a href="https://chirotonia.s3.eu-south-1.amazonaws.com/chirotonia_registrazione.mp4" rel="noreferrer" target='_blank'>Tutorial registrazione</a></Typography>
                <Typography className={classes.text}><a href="https://chirotonia.s3.eu-south-1.amazonaws.com/Chirotonia_votazione.mp4" rel="noreferrer" target='_blank'>Tutorial votazione</a></Typography>
            </div>
        </Card>
    ) 
}

export default Credits