import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop:20,
      flexDirection: 'column',
    },
    table:{
      width: '100%',
      alignItems:'center',  
    },
    tableHead:{
      background:'#eeeeee',
      alignItems:'center',
    },
    tableCell:{
      width: '80%',
    },
    Paper:{
      width: '80%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 20,
    },
    title: {
      flexGrow: 1,
      fontFamily:'Segoe UI',
      fontWeight:'Regular',
      fontSize: 20,
      lineHeight:'1',
    },

    subTitle: {
      flexGrow: 1,
      fontFamily:'"Segoe UI"',
      fontWeight:'Regular',
      fontSize: 18,
      lineHeight:'1',
    },

    text:{
      fontFamily:'"Segoe UI"',
      fontWeight:'Regular', 
      fontSize: 16,
    },
  });

export default useStyles;