import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root:{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginTop:20,
        marginLeft: 30,
        marginRight: 30,
    },
    dialog:{
        background: '#eeeeee',
    },
    
    ButtonStyleConfirm:{
        marginTop:20,
        marginBottom: 15,
        padding: '12px 20px',
        backgroundColor: '#eeeeee',
        lineHeight: 1.5,
        fontFamily:'"Segoe UI"',
        fontSize:16,
        width:'170px',
        height:'60px',
        float: 'right',
    },
    ButtonStyleBack:{
        marginTop:20,
        marginBottom: 15,
        padding: '12px 20px',
        backgroundColor: '#eeeeee',
        lineHeight: 1.5,
        fontFamily:'"Segoe UI"',
        fontSize:16,
        width:'170px',
        height:'60px',
        float: 'left',
    },

    ButtonStyle: {
        padding: '12px 20px',
        backgroundColor: '#eeeeee',
        lineHeight: 1.5,
        fontFamily:'"Segoe UI"',
        fontSize:16,
        width:'170px',
        height:'60px',
    },

    textTitle: {
        fontFamily:'"Segoe UI"',
        fontWeight:'500', 
        fontSize: 18,
    },

    textAlert: {
        fontFamily:'"Segoe UI"',
        fontWeight:'Regular', 
        fontSize: 16,
    }

});

export default useStyles