import {useEffect, useState} from 'react';
import endpoint from '../../scripts/endpoint';
import chirotonia from '../../scripts/chirotonia';

function InfoVote() {
    const [results, setResults] = useState({})
    
    useEffect(()=>{
        getVote();
    }, [])

    async function getVote() {
        const response = await fetch(endpoint.ENDPOINT_CANDIDATI);
        const data = await response.json();
        let tessera = chirotonia.TesseraElettorale.fromLocalStorage();
        if (!tessera.email.includes('backupCard')) {
            data.votazioni = data.votazioni.filter(v => v.voters.includes(tessera.email));   
        }
        setResults(data);
    }
    return results
}

export default InfoVote;