import BN from 'bn.js'
import Web3 from 'web3'

import ec from './alt_bn128'

// Utility for easily compute operations among BN in mod p and mod n
// var redPCtx = BN.red(ec.curve.p);
const redNCtx = BN.red(ec.curve.n);
// keccak256 implemented in Solidity
const sha3 = Web3.utils.soliditySha3;
// Constant to compute sqrt in mod p through pow
const curveSqrtExp = new BN("C19139CB84C680A6E14116DA060561765E05AA45A1C72A34F082305B61F3F52", 'hex')

/**
 * Converts an hex string with leading 0x to a bn.js
 * @param {string} hexString 
 * @returns {BN} the big number representing the hex string
 */
function sha3ToBN(hexString) {
    return new BN(hexString.split('x')[1], 'hex')
}

/**
 * Derives an EC poitn using hash as x coordinate
 * @param {string|BN} hash 
 * @returns 
 */
function hashToPoint(hash) {
    let x = hash;
    if (typeof hash !== 'object') {
        x = new BN(hash, 'hex');
    }
    x = x.mod(ec.curve.n);
    while (true) {
        try {
            // Use a custom implementation with sqrt as pow instead of classic for consistency with solidity
            return ec.curve.pointFromXWithPow(x, curveSqrtExp);
        }
        catch (err) {
            x = x.add(new BN(1));
        }
    }   
}

class LinkableRingSignatureSchema {
    constructor(pkeys) {
        this.pkeys = pkeys;
    }

    // Compute the hash of the public key list according to contract implementation
    pkeysHash() {
        let hashAcc = false;
        for (let index = 0; index < this.pkeys.length; index++) {
            const pk = this.pkeys[index];
            if (!hashAcc) {
                hashAcc = sha3ToBN(sha3(pk.getX()));
            } else {
                hashAcc = sha3ToBN(sha3(hashAcc, pk.getX()));
            }
        }
        return hashAcc;
    }

    sign(secretKey, signerIndex, message) {
        if (this.pkeys.length < 2) {
            throw new Error("Can't sign with a size of 1 or less");
        }

        // TODO: Check that signerIndex corresponds
        let alpha = ec.genKeyPair().getPrivate()
        let cArray = [];
        for (let index = 0; index < this.pkeys.length; index++) {
            cArray.push(null);
        }
        let tArray = [];
        for (let index = 0; index < this.pkeys.length; index++) {
            tArray.push(ec.genKeyPair().getPrivate());
        }
    
        // Compute the point on the curve with the given message hash as x
        let messagePoint = hashToPoint(message);
        // Compute the point on the curve with the given PKs list hash as x
        let pkeysPoint = hashToPoint(this.pkeysHash(this.pkeys));
        // Compute the tag of the linkable ring signature
        let tag = pkeysPoint.mul(secretKey);
    
        let baseHash = sha3ToBN(sha3(messagePoint.getX(), messagePoint.getY(), tag.getX(), tag.getY()));
        for (let index = 0; index < this.pkeys.length; index++) {
            const ringIndex = (signerIndex + index) % this.pkeys.length;
            const pk = this.pkeys[ringIndex];
            let c = index === 0 ? alpha : cArray[(ringIndex+this.pkeys.length-1)%this.pkeys.length];
            let t = tArray[ringIndex];
    
            let a = ec.curve.g.mul(t).add(pk.mul(c));
            let b = pkeysPoint.mul(t).add(tag.mul(c));
    
            let indexHash = sha3ToBN(sha3(tag.getX(), tag.getY(), a.getX(), a.getY(), b.getX(), b.getY()));
            cArray[ringIndex] = sha3ToBN(sha3(baseHash, indexHash));
        }
    
        alpha = alpha.toRed(redNCtx);
        alpha.redISub(new BN(cArray[(signerIndex+this.pkeys.length-1)%this.pkeys.length]).toRed(redNCtx));
        let tRed = tArray[signerIndex].toRed(redNCtx);
        let skRed = secretKey.toRed(redNCtx);
        tArray[signerIndex] = tRed.redAdd(skRed.redMul(alpha)).fromRed();
    
        return {
            tees: tArray,
            anchor: cArray.pop(),
            tag: tag,
            message: message
        }
    }

    /**
     * 
     * @param {BN[]|string[]} tees 
     * @param {BN|string} anchor 
     * @param {object} tag se non è un punto allora si aspetta un dizionario { x: ..., y: ...}
     * @param {BN|string} message 
     * @returns {boolean}
     */
    verify(tees, anchor, tag, message) {
        if (tees.length !== this.pkeys.length) {
            return false;
        }

        if (typeof tees[0] === 'string') {
            let tmpTees = [];
            tees.forEach(t => {
                tmpTees.push(new BN(t, "hex"));
            });
            tees = tmpTees;
        }

        if (typeof anchor === 'string') {
            anchor = new BN(anchor, "hex");
        }

        if (typeof tag.x === 'string') {
            tag = ec.curve.point(tag.x, tag.y);
        }

        let messagePoint = hashToPoint(message);
        let pkeysPoint = hashToPoint(this.pkeysHash(this.pkeys));
    
        let baseHash = sha3ToBN(sha3(messagePoint.getX(), messagePoint.getY(), tag.getX(), tag.getY()));
    
        let c = anchor;
        for (let index = 0; index < this.pkeys.length; index++) {
            const pk = this.pkeys[index];
            const t = tees[index];
    
            let a = ec.curve.g.mul(t).add(pk.mul(c));
            let b = pkeysPoint.mul(t).add(tag.mul(c));
    
            let indexHash = sha3ToBN(sha3(tag.getX(), tag.getY(), a.getX(), a.getY(), b.getX(), b.getY()));
            c = sha3ToBN(sha3(baseHash, indexHash));
        }
        return c.toString() === anchor.toString();
    }

    /**
     * 
     * @param {object} lrs1
     * @param {object} lrs1
     * @returns 
     */
    linked(lrs1, lrs2) {
        return lrs1.tag.getX().toString() === lrs2.tag.getX().toString();
    }

    static logSignature(lrs) {
        console.log("Tees:")
        lrs.tees.forEach(t => {
            console.log(t.toString('hex'));
        })
        console.log("Anchor:");
        console.log(lrs.anchor.toString('hex'));
        console.log("Tag:");
        console.log(lrs.tag.getX().toString('hex'));
        console.log(lrs.tag.getY().toString('hex'));
        console.log("Message:");
        console.log(lrs.message.toString('hex'));
    }

}

export default LinkableRingSignatureSchema