class EventEmitter {
    constructor() {
        this.callbacks = {};
    }

    /**
     * 
     * @param {string} event 
     * @param {callable} callback 
     * @returns 
     */
    on(event, callback) {
        this.callbacks[event] = callback;
        return this;
    }

    /**
     * 
     * @param {string} event 
     * @param {any} data 
     */
    fire(event, data) {
        if (event in this.callbacks) {
            this.callbacks[event](data);
        }
    }
}

export default EventEmitter