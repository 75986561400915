import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InfoAlert from '../vote_components/InfoAlert';
import useStyles from '../styles/startVoteTheme';
import endpoint from '../../scripts/endpoint';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import chirotonia from '../../scripts/chirotonia';

function StartVote() {
    
    const classes = useStyles();
 
    let history = useHistory();

    const [open, setOpen] = useState(false);
    const [conferma, setConferma] = useState(false);
    const [usaTessera, setUsaTessera] = useState(false);
    const [email, setEmail] = useState('');

    function readFile(ev){
        var importedFile= ev.target.files[0];
        var reader= new FileReader();
        reader.readAsText(importedFile);
        reader.onload = async (ev) => {
            try {
                let tessera = chirotonia.TesseraElettorale.initFromJSON(ev.target.result);
                tessera.toLocalStorage();
                setOpen(false)
                setConferma(true) 
                setEmail(tessera.email)
            } catch (error) {
                return(
                    <InfoAlert text={"Errore"}> </InfoAlert>
                );
            }
       }
    }

    function handleClose(){
        setOpen(false);
    };

    function handleCloseConferma(){
        setConferma(false);
    };

    function openUpload(){
        setOpen(true);
        setUsaTessera(false);

    }

    async function handleRegistration(){
        window.location.assign(endpoint.ENDPOINT_LOGIN);
     }

    function handleClick(){
        try {
            let tesseraElettorale = chirotonia.TesseraElettorale.fromLocalStorage();
            setUsaTessera(true);
            setEmail(tesseraElettorale.email)
        } catch (error) {
            setOpen(true);
        } 
    };

    function goToVoting(){
        history.push('/voting', {email:email});
    }

    return (
         <div className={classes.root}>
             <Button variant="contained" 
                        className={classes.ButtonRegistration} 
                        onClick={handleRegistration} 
                        endIcon={<RecentActorsIcon style={{ fontSize: 30 }}/>}
                    > 
                    Registra tessera elettorale
                </Button>
             <Button variant="contained" 
                    className={classes.ButtonVote} 
                    onClick={handleClick} 
                    endIcon={< HowToVoteIcon style={{ fontSize: 30 }}/>}> 
                Inizia votazione 
            </Button>

            <Dialog open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title" className={classes.dialog} >
                <Typography  className={classes.Dialogtitle}> Carica qui la tua tessera elettorale </Typography>
            </DialogTitle>
            <DialogActions className={classes.dialog}>
                <div >
                    <input
                        accept=".json"
                        className={classes.input}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={(ev) => readFile(ev)}
                    />
                    <label htmlFor="contained-button-file">
                        <Button variant="contained" 
                                component="span" 
                                className={classes.button} 
                                endIcon={<CloudUploadIcon/>} 
                        >
                            Upload
                        </Button>
                    </label>
                </div>
            </DialogActions>
        </Dialog>

        <Dialog open={conferma}
                onClose={handleCloseConferma}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                severity="info">
            <DialogTitle id="alert-dialog-title" className={classes.dialog} >
                <Typography  className={classes.Dialogtitle}> Caricamento avvenuto con successo! </Typography>
            </DialogTitle>
            <DialogActions className={classes.dialog}>
                        <Button variant="contained" 
                                component="span" 
                                className={classes.button}  
                                onClick={goToVoting}>
                            Vota
                        </Button>
            </DialogActions>
        </Dialog>

        <Dialog open={usaTessera}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                severity="info">
            <DialogTitle id="alert-dialog-title" className={classes.dialog} >
                <Typography  className={classes.Dialogtitle}> Tessera elettorale presente nel browser. Utilizzarla? </Typography>
            </DialogTitle>
            <DialogActions className={classes.dialog}>
                        <Button variant="contained" 
                                component="span" 
                                className={classes.ButtonStyle1}  
                                onClick={goToVoting}>
                            Si
                        </Button>
                        <Button variant="contained" 
                                component="span" 
                                className={classes.ButtonStyle1}  
                                onClick={openUpload}>
                            No
                        </Button>
            </DialogActions>
        </Dialog>

         </div>
    );
}

export default StartVote;