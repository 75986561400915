import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import React, {useState} from 'react';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import AlertConfermaVoto from './alertConfermaVoto';
import { useLocation } from "react-router-dom";
import SchedaVotazione from './SchedaVotazione';
import useStyles from '../styles/elencoCandidatiTheme';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";

function ElencoCandidati(){

    const location = useLocation();

    const results = location.state.results;
    const voteId = location.state.voteId;
    const state = results.state;

    const [value, setValue] = useState(null)
    const [open, setOpen] = useState(true);
    const [id, setID] = useState(null);

    function handleChange(event) {
        if (event.target.value === value) {
            setValue(null);
            setID(null);
        } 
        else {
            setValue(event.target.value);
            setID(event.target.id);
        }
    }
    const classes = useStyles();

    return(
        <div >
            <div>
                <SchedaVotazione results={results}></SchedaVotazione>
                {(results.candidati || results.eleggibili) && 
                <AlertConfermaVoto value={value} id={id} voteId={voteId} state={state} votazione={results}></AlertConfermaVoto>}
            </div>
            <div className={classes.root}>
                <TableContainer className={classes.Paper} component={Paper}>
                    <Table className={classes.table}>
                        <TableHead >
                            <TableRow className={classes.tableHead}>
                                <TableCell >
                                    <Typography className={classes.title}>
                                        Vota
                                    </Typography>
                                </TableCell>
                                <TableCell  >
                                    <Typography className={classes.title}>
                                        Candidato
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {results.candidati && results.candidati.map(candidato =>  
                                <TableRow key={candidato.id}>
                                    <TableCell >
                                        <RadioGroup value={value}>
                                            <FormControlLabel 
                                                value = {candidato.descrizione}
                                                control={<Radio id={candidato.id} onClick={handleChange} color="primary"/>}/>  
                                        </RadioGroup>
                                    </TableCell>
                                    <TableCell> 
                                        <Typography className={classes.text}>
                                                {candidato.descrizione}
                                        </Typography> 
                                    </TableCell>
                                </TableRow>          
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer className={classes.Paper} component={Paper}>
                    <Table className={classes.table}>
                    <TableHead >
                        <TableRow className={classes.tableHead}>
                            <TableCell>
                                <IconButton
                                    aria-label="expand row"
                                    size="small"
                                    onClick={() => setOpen(!open)}
                                >
                                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                <Typography className={classes.title}>
                                    Eleggibili
                                </Typography>  
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={2}>
                                <Collapse in={open} timeout="auto" unmountOnExit>
                                    <Table className={classes.table}>
                                        <TableBody>
                                            {results.eleggibili && results.eleggibili.map(eleggibile => (
                                            <TableRow key={eleggibile.id}>
                                                <TableCell component="th" scope="row">
                                                <RadioGroup value={value}>
                                                    <FormControlLabel 
                                                        value = {eleggibile.descrizione} 
                                                        control={<Radio id={eleggibile.id} onClick={handleChange} color="primary"/>} />  
                                                </RadioGroup>
                                                </TableCell>
                                                <TableCell>{eleggibile.descrizione}</TableCell>
                                            </TableRow>
                                          ))}
                                        </TableBody>
                                    </Table>
                                </Collapse>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                    </Table>
                </TableContainer>
            </div>
                        
            

        </div>
    )
}

export default ElencoCandidati