import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import useStyles from '../styles/schedaEelettoraleTheme';

function SchedaElettorale(props) {
    
  const classes = useStyles();

    return (
      <div className={classes.rootSchedaElettorale}> {/*tabella scheda elettorale*/}
        <TableContainer className={classes.PaperSchedaElettorale} component={Paper}>
          <Table className={classes.table} >
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell> 
                  <Typography className={classes.titleSchedaElettorale}> Scheda elettorale </Typography> 
                </TableCell> 
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography className={classes.textSchedaElettorale}> <b>Email: </b> {props.email}</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
         </TableContainer>
       </div>
    )
}

export default SchedaElettorale;