import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
      root:{
        display: 'flex',
        background: '#eeeeee',
        flexDirection: 'column',
        marginTop:80,
        marginBottom: 140,
        paddingLeft:30,
        paddingRight:30,
        paddingBottom:20,
        paddingTop:20,
      },

      rootLink:{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
      
    text:{
        fontFamily:'Segoe UI',
        fontWeight:'Regular', 
        fontSize: 15,
        marginTop: 6,
        marginBottom: 6,
      },
});

export default useStyles