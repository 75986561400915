import React from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import './App.css';
import Home from './components/Home';
import VotazioniDisponibili from './components/vote_components/votazioniDisponibili';
import TitleBar from './components/TitleBar';
import HeaderBar from './components/HeaderBar';
import VoteRegistration from './components/regVote_components/VoteRegistration';
import ElencoCandidati from './components/vote_components/ElencoCandidati';
import Registration from './components/registration_components/Registration';
import CreazioneTessera from './components/registration_components/creazioneTessera';

const App = () => {
    
    return (
        <Router>
            <div className="App">
            <HeaderBar/>
            <TitleBar/> 
            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/voting" component={VotazioniDisponibili}/>
                <Route exact path="/voting/candidates" component={ElencoCandidati}/>
                <Route exact path="/validating" component={VoteRegistration}/>
                <Route exact path="/registration" component={Registration}/> 
                <Route exact path="/registration/newTessera" component={CreazioneTessera}/>           
            </Switch>
            </div> 
        </Router>
        
    )
}

export default App;