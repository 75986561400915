import React from 'react';
import InfoVoter from "./getInfoVoter";
import InfoAlert from '../vote_components/InfoAlert';
import AlertReg from './alertRegistrazione';
import { useHistory} from "react-router-dom";
function Registration(){
   
   const results = InfoVoter();
    let history=useHistory();

    if(results.voting){
        if(results.voting.enabled){
            if (!results.voting.registered){
                history.push("/registration/newTessera", {email: results.email});
                return(
                    <div>
                    </div>
                )
            }   
            else{
                return(
                    <AlertReg text={'Hai già una tessera elettorale! Vuoi crearne una nuova?'} email={results.email}></AlertReg>
                )           
            }
        }
        else{
            return(
            <InfoAlert text={'Non sei abilitato a votare'}></InfoAlert>
            )
        }
    }
    else{
        return(
            <InfoAlert text={'Errore. Contattare un amministratore.'}></InfoAlert>
        )
    }
} 

export default Registration;