import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import DialogTitle from '@material-ui/core/DialogTitle';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import DoneIcon from '@material-ui/icons/Done';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import { Button} from '@material-ui/core';
import { useHistory } from "react-router-dom";
import useStyles from '../styles/alertConfermaVotoTheme';

function AlertConfermaVoto(props){
    const classes = useStyles();

    const [open, setOpen] = useState(false);
    const [text, setText] = useState('');
    const [password, setPassword] = useState('');

    let history = useHistory();

    function handleClickOpen(){
            setOpen(true);
            if(props.value === null){
              setText("scheda bianca");
            }
            else{
              setText(props.value);
            }
    }
    
    function handleClose(){
        setOpen(false);
    }

    function handleClick(){
        if(password.length !==0){
            history.push('/validating', {password: password, idScelta: props.id, voteId: props.voteId})
        }
    }

    return(  
       <div>
           <div className={classes.root}>
           <Button className={classes.ButtonStyleBack} 
                variant="contained" 
                color="default" 
                size="large" 
                onClick={()=>{history.goBack()}} 
                startIcon={<ArrowBackIcon/>}>
            Torna indietro
            </Button>  
            <Button className={classes.ButtonStyleConfirm} 
                variant="contained" 
                color="default" 
                size="large" 
                onClick={handleClickOpen} 
                disabled={Date.parse(props.votazione.voteStart) - Date.now() > 0}
                endIcon={<DoneIcon/>}>
            Conferma
            </Button>  
            </div>
        <Dialog open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title" className={classes.dialog} >
                <Typography className={classes.textTitle}>Confermare votazione?</Typography>
            </DialogTitle>
            <DialogContent className={classes.dialog}>
                <Typography  className={classes.textAlert}> Hai votato per {text}.</Typography>
                <TextField
                    autoFocus
                    margin="dense"
                    id="password"
                    label="Inserisci password tessera elettorale"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    fullWidth
                />
            </DialogContent>
            <DialogActions className={classes.dialog}>
                <Button onClick={handleClose} 
                        variant="contained" 
                        color="default" 
                        startIcon={<ArrowLeftIcon/> } 
                        className={classes.ButtonStyle}>
                    Torna indietro
                </Button>
                <Button onClick={handleClick} 
                        variant="contained" 
                        color="default" 
                        endIcon={<DoneIcon/>} 
                        className={classes.ButtonStyle}>
                    Conferma
                </Button>
            </DialogActions>
        </Dialog>

        </div> 
    )
}

export default AlertConfermaVoto