import React from 'react';
import '../App.css';
import Typography from '@material-ui/core/Typography';
import useStyles from './styles/homeTheme';
import StartVote from './vote_components/StartVote';
import FooterBar from './footerBar';
import Credits from './Credits';
function Home() {
  
    const classes = useStyles();

    return (
         <div className={classes.root}>
            <Typography className={classes.title}> Benvenuti nell'area di voto! </Typography>
            <StartVote></StartVote>
            <Credits></Credits>
            <FooterBar></FooterBar>
            
            
         </div>
    );
}

export default Home;