import {useEffect, useState} from 'react';
import endpoint from '../../scripts/endpoint';
import chirotonia from '../../scripts/chirotonia';
import InfoAlert from '../vote_components/InfoAlert';

function NewTessera(props) {
    const [results, setResults] = useState({})
    
    useEffect(()=>{
        newTessera();
    }, [])

    function newTessera(){
        let TesseraElettorale = new chirotonia.TesseraElettorale(props.email); 
        TesseraElettorale.init(props.password).then(tessera => {
          let tesseraJSON = TesseraElettorale.toJSON(true); 
          postTessera(tesseraJSON);
          props.saveTessera(tesseraJSON);
          props.resetPassword();
        });   
    }
     
      async function postTessera(body){
        const response = await fetch(endpoint.ENDPOINT_VOTER, 
          {method: 'POST', 
          headers: { "Content-Type": "application/json" }, 
          body: body})
        const responseJSON = await response.json();
        const status =  response.status;
        const message = responseJSON.message;
        const res={
            status: status,
            message: message
        };
    
        setResults(res)
      }
      
      if(results){
          if(results.status === 200){
              props.nextStep();
              return(
                <h1>{results.message}</h1>
              )
          }
          else{
              return(
              <InfoAlert text={results.message}></InfoAlert>
              )
          }
        }
        else{
            return(
                <InfoAlert text={"Errore"}></InfoAlert>
            )
        }
      
    
}
     
export default NewTessera